.cardWrapper {
  background: url("../../../static/images/snowfall-gif.gif");
  background-size: cover;
  margin: unset;
  max-width: unset;
  position: relative;
}

.cardWrapper button {
  font-size: 1.2rem;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: -webkit-fill-available;
  background-color: rgba(255, 255, 255, 0.1);
}

.merryChristmasText {
  font-family: "Festive", cursive;
  text-shadow: 4px 2px #ff0000;
  display: flex;
  justify-content: center;
}

.merryChristmasText svg {
  overflow: visible;
}

.lgText {
  display: none;
  font-size: 64px;
}

.smText {
  font-size: 43px;
  height: 120px;
}

@media (min-width: 400px) {
  .lgText {
    display: inherit;
  }

  .smText {
    display: none;
  }

  .merryChristmasText svg {
    width: 400px;
  }
}

.merryChristmasText path {
  fill: transparent;
}

.merryChristmasText text {
  fill: #fff;
}

.christmasLights {
  position: relative;
  margin: auto;
  width: 400px;
  height: 70px;
  max-width: 100%;
}

.christmasLights img:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  animation-name: toggleVisbility;
  animation-delay: 0.5s;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.christmasLights img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  animation-name: toggleVisbility;
  animation-delay: -0.5s;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.christmasTree {
  position: relative;
  margin: auto;
  width: 250px;
  height: 500px;
}

.christmasTree > img:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  animation-name: toggleVisbility;
  animation-delay: 1s;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.christmasTree > img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  animation-name: toggleVisbility;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes toggleVisbility {
  0% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
}

.giftsWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
}

.clickMeText {
  font-family: 'Amatic SC', cursive;
  font-weight: 900;
  position: absolute;
  top: -50px;
  margin-left: -38px;
  animation-name: clickMeAnim;
  animation-duration: 1.75s;
  animation-iteration-count: infinite;
}

.clickMeText:after {
  font-family: "Font Awesome 5 Free";
  content: '\f063';
  font-weight: 700;
}

@keyframes clickMeAnim {
  0% { font-size: 1.5rem; }
  50% { font-size: 2.2rem; }
  100% { font-size: 1.5rem; }
}

.giftPresentation {
  position: absolute;
  font-size: 1.2rem;
  font-family: 'Bebas Neue', cursive;
  animation-name: giftModalSlideIn;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

@media(min-width: 400px) {
  .giftPresentation {
    max-width: 400px;
    margin-left: -200px;
    top: 20px;
    left: 50%;
  }
}

.giftPresentation > div {
  margin: 0 auto;
}

.giftPresentation img {
  max-width: 95%;
  max-height: 300px;
}

.giftCardText {
  font-size: 5rem;
}

.giftTag > div {
  background: transparent;
  box-shadow: none;
}

.giftTagWrapper {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  animation-name: giftModalSlideIn;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.giftTagBody {
  font-family: 'Oooh Baby', cursive;
  font-size: 1.5rem;
  font-weight: 600;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 1;
}

@media(min-width: 480px) {
  .giftTagBody {
    padding: 60px;
  }
}

.giftTagWrapper::after {
  content: url('../../../static/images/gift-tag.svg');
  position: relative;
}

@keyframes giftModalSlideIn {
  0% { margin-top: 500px; }
  100% { margin-top: 0; }
}

.giftsWrapper > div:nth-child(2) :global(.fas) {
  background: url("../../../static/images/red-white-wrapping-paper.jpeg");
  background-size: 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.giftsWrapper > div:nth-child(1) :global(.fas) {
  background: url("../../../static/images/blue-wrapping-paper.jpeg");
  background-size: 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.giftsWrapper > div:nth-child(3) :global(.fas) {
  background: url("../../../static/images/red-green-wrapping-paper.jpeg");
  background-size: 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.giftBow img:nth-child(1) {
  max-width: 100px;
  position: absolute;
  left: 8px;
  transform: rotate(
-45deg);
  top: 5px;
}

.giftBow img:nth-child(2) {
  max-width: 100px;
  position: absolute;
  right: 8px;
  transform: rotate(
45deg);
  top: 5px;
}